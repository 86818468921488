export const colors = {
  second1: '#223354',
  while: '#fff',
  mainColor2: '#FEA628',
  black: '#000',
  homeGroupTextBody: '#223354',
  gray2: '#C4C4C4',
  red100: '#FF0000',
  bgInfo: '#FBFBFB',
  mainColor100: '#FEA628',
  textCaption: '#909090',
  mainColor1: '#F9FBFC',
  second6: '#FFF6E9',
  second2: '#E8F0F9',
  second3: '#F3F7FB',
  second5: '#C9D5EB',
  secondPink5: '#FABB89',
  text: '#464646',
  stroke: '#C4C4C4',
  grey3: '#F3F3F4',
  grey: '#FBFBFB',
  semanticRed: '#E74C3C',
  semanticGreen: '#2ECC71',
  second4: '#2C8CE4',
  bgComment: '#f7f7f7',
  lineComment: '#bdbdbd',
  bgBottomCm: '#f1f1f1',
  colorText: '#223354',
  bgAvatar: ' #cccccc',
  bgCount: '#e8f0f9',
};
