export const DISABLE_SERIES = true;

export const ENV_NAME = {
  DEVELOP: 0,
  TEST: 1,
  STAGING: 2,
  PRODUCT: 3,
};

export const ENVIRONMENT = ENV_NAME.DEVELOP;

const ApiURLs = [
  'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // dev
  'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // test
  'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // staging
  'https://acms-wiki-presentation.dev4.amelacorp.com/api/', // product
];

const configs = {
  APP_ENV: ENVIRONMENT,
  API_DOMAIN: ApiURLs[ENVIRONMENT],
  API_DOMAIN_LOGIN: process.env.PAGE_LOGIN,
  systemAdminId: process.env.REACT_APP_SYSTEM_ADMIN_ID,
  DOMAIN_AMS_ME: process.env.REACT_APP_DOMAIN_AMS_ME,
  DOMAIN_AMS_WORKFLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_AMS_RECRUIT: process.env.REACT_APP_DOMAIN_AMS_RECRUIT,
  DOMAIN_AMS_BO: process.env.REACT_APP_DOMAIN_AMS_BO,
  DOMAIN_AMS_WIKI: process.env.REACT_APP_DOMAIN_AMS_Wiki,
  DOMAIN_AMS_PROJECT: process.env.REACT_APP_DOMAIN_AMS_PROJECT,
};

export default configs;
