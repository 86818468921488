import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const NewFeedGroupWrapper = styled.div`
  .ant-tabs-nav {
    width: 100vw;
    margin-left: ${isMobile ? '-20px' : 'calc(561px - 50vw)'};
    border-top: ${!isMobile ? 'unset' : '1px solid #e0e0e0'};
    border-bottom: ${!isMobile ? 'unset' : '1px solid #e0e0e0'};
    ::before {
      display: none;
    }
    .ant-tabs-nav-list {
      margin: ${isMobile ? '0 0 0 20px' : '0 !important'};
      padding-left: ${isMobile ? 'unset' : 'calc(50vw - 570px)'};
      border-top: ${isMobile ? 'unset' : '1px solid #e0e0e0'};
      border-bottom: ${isMobile ? 'unset' : '1px solid #e0e0e0'};
      width: ${isMobile ? '100%' : 'calc(290px + 50vw) !important'};
      height: 42px;
      .tab-col-name {
        font-size: 14px;
      }
    }
  }
  .ant-tabs-content-holder {
    div {
      :focus {
        outline: none;
      }
    }
  }
`;

const SpaceStyle = styled.div<any>`
  padding: ${(props) => (props?.padding ? props?.padding : 'unset')};
  width: ${(props) => props?.width || 'unset'};
`;

export { SpaceStyle };

export default NewFeedGroupWrapper;
