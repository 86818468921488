import { Avatar } from 'antd';
import styled from 'styled-components';

interface Props {
  marginLeftTextAvatar?: number | string;
}
const AvatarTextWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
`;

const TextAvatarStyle = styled.div<any>`
  margin-left: ${(props) =>
    typeof props?.marginLeft === 'number'
      ? `${props.marginLeft !== undefined ? props.marginLeft : 7}px`
      : props?.marginLeft || '7px'} !important;
  cursor: ${(props) => props?.cursor || 'unset'};
  color: ${(props) => props?.color || 'unset'};
  font-size: ${(props) => props?.fontSize || '14px'};
  font-weight: ${(props) => props?.fontWeight || 400};
  flex: ${(props) => props?.flex || 'unset'};
`;

const AvatarStyle = styled(Avatar)<any>`
  margin-left: ${(props) =>
    typeof props?.marginLeft === 'number'
      ? `${props.marginLeft !== undefined ? props.marginLeft : 0}px`
      : props?.marginLeft || 'unset'} !important;
  background-color: ${(props) => (props?.backGround ? props?.backGround : 'unset')};
  cursor: ${(props) => props?.cursor || 'unset'};
`;

export { AvatarStyle, TextAvatarStyle };
export default AvatarTextWrapper;
