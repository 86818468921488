import configs, { ENV_NAME } from 'config';
import { message } from 'antd';

export const handleErrorMessage = (error: any) => {
  message.destroy();
  message.error(getErrorMessage(error));
  if (configs.APP_ENV !== ENV_NAME.PRODUCT) {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || 'Something went wrong!';
};

export const formatUrl = (url?: string) => {
  if (url) return url.replace(/ /g, '%20');
  return '';
};

export const renderCountTab = (total?: number) => {
  return !total ? ' (0)' : ` (${total})`;
};

function getPositionAtCenter(element: any) {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

function getDistanceBetweenElements(a: any, b: any) {
  const aPosition = getPositionAtCenter(a);
  const bPosition = getPositionAtCenter(b);

  return Math.sqrt(Math.pow(aPosition.x - bPosition.x, 2) + Math.pow(aPosition.y - bPosition.y, 2));
}

export { getPositionAtCenter, getDistanceBetweenElements };
