import styled from 'styled-components';
import { Tabs } from 'antd';
import { colors } from 'common/styled-base';
const { TabPane } = Tabs;

export const GrayWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #f3f3f4;
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
  }
  border-bottom: 1px solid #e0e0e0;
`;

export const AdminWrapper = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  .eTvqIa {
    background: none;
  }
  .ant-tabs {
    overflow: visible;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
    background-color: rgb(255, 255, 255, 0.3);
    /* background-color: #F3F3F4; */
  }
  .ant-tabs,
  .ant-tabs-top {
    width: auto;
  }
  .image-fixed-home-page {
    background-image: url(${(props) => props.theme});
    height: 1000px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
  }
  .ant-tabs-tabpane,
  .ant-tabs-tabpane-active {
    background-color: none;
    background: none;
  }
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
`;

export const CustomTabs = styled(Tabs)`
  font-size: 0.9em;
  width: 100vw;
  .ant-tabs-nav {
    height: 100%;
  }

  .ant-tabs-tab {
    font-size: 1.26984em;
    color: ${colors.textCaption};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #223354;
    font-weight: 500;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #000000;
    pointer-events: none;
  }
`;

export const CustomTabPane = styled(TabPane)`
  background-color: white;
`;

export const Space = styled.div`
  flex: 1;
`;

export const NoPermission = styled.div`
  width: 100%;
  height: 70vh;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;
